import { getValueOrDefault } from '@k_frontend/core'
import { OfferResponse } from 'services/ofertas/ofertas.types'
import { formatProductFriendlyName } from 'utils/friendlyName'

import { formatStampsType } from 'utils/formatStampsType'
import { OfferModel } from './ofertas.types'

function offerModel(data: OfferResponse): OfferModel {
  function getProductStamp(stampName: string) {
    const findedStamp = data.selos?.find((selo) => selo.nome === stampName)

    if (findedStamp) {
      return {
        id: findedStamp.codigo,
        name: findedStamp.nome,
        title: findedStamp.titulo,
        backgroundColor: findedStamp.cor_fundo,
        fontColor: findedStamp.cor_fonte,
        type: formatStampsType(findedStamp.tipo)
      }
    }

    return null
  }

  function getHighlightedStampFilters(stampFilters: string[]) {
    const filteredStampFilters = data.selos.filter((selo) =>
      stampFilters.includes(selo.nome)
    )

    const formatedStamps = filteredStampFilters.map((selo) => {
      return {
        name: selo.nome,
        title: selo.titulo
      }
    })

    return formatedStamps
  }

  try {
    const products = data.produtos?.map((product) => ({
      code: product.codigo,
      available: getValueOrDefault(product.disponibilidade, false),
      offerCode: product.oferta_codigo,
      name: product.produto,
      friendlyName: formatProductFriendlyName(product.produto),
      sellerName: getValueOrDefault(product.vendedor_nome, 'KaBuM!'),
      sellerId: Number(getValueOrDefault(product.vendedor_codigo, 0)),
      category: getValueOrDefault(
        `${product.dep_nome}/${product.sec_nome}`,
        ''
      ),
      manufacturer: getValueOrDefault(product.fabricante_nome, ''),
      image: product.imagem,
      oldPrice: getValueOrDefault(Number(product.vlr_normal), 0),
      price: getValueOrDefault(Number(product.vlr_normal), 0),
      priceWithDiscount: getValueOrDefault(product.vlr_oferta, 0),
      primePrice: getValueOrDefault(Number(product.preco_prime), 0),
      primePriceWithDiscount: getValueOrDefault(
        Number(product.preco_desconto_prime),
        0
      ),
      oldPrimePrice: getValueOrDefault(Number(product.preco_antigo_prime), 0),
      offerDiscount: getValueOrDefault(product.desconto, 0),
      offerDiscountPrime: getValueOrDefault(Number(product.desconto_prime), 0),
      maxInstallment: getValueOrDefault(product.max_parcela, ''),
      maxInstallmentPrime: getValueOrDefault(product.max_parcela_prime, ''),
      quantity: getValueOrDefault(product.quantidade, 0),
      rating: product.tmp_avaliacao_nota,
      ratingCount: getValueOrDefault(product?.tmp_avaliacao_numero, 0),
      hashCode: product.di,
      stamp: getProductStamp(product.stamp),
      flags: {
        openbox: getValueOrDefault(!!product.openbox, false),
        freeShipping: getValueOrDefault(!!product.frete_gratis, false),
        freeShippingPrime: getValueOrDefault(
          !!product.frete_gratis_prime,
          false
        ),
        isPreOrder: getValueOrDefault(product?.flags?.is_pre_order, false),
        isFlash: getValueOrDefault(!!product?.entrega_flash || false)
      },
      offerDates: {
        startDate: getValueOrDefault(product.data_ini, 0),
        endDate: getValueOrDefault(product.data_fim, 0)
      },
      colors: {
        main: getValueOrDefault(data.oferta?.cor?.primaria, ''),
        text: getValueOrDefault(data.oferta?.cor?.letra, '#FFFFFF')
      },
      offerInfo: {
        mainColor: data.oferta?.cor?.primaria,
        secondaryColor: data.oferta?.cor?.secundaria,
        textColor: getValueOrDefault(data.oferta?.cor?.letra, '#FFFFFF'),
        startDate: getValueOrDefault(product.data_ini, 0),
        endDate: getValueOrDefault(product.data_fim, 0),
        title: data.oferta?.title || '',
        description: data.oferta?.description || ''
      },
      tagCode: getValueOrDefault(product.tag_code, 0),
      offerIdMarketplace: product.id_marketplace,
      isHighlight: getValueOrDefault(product.featured_product, false)
    }))

    const pagination = {
      prev: getValueOrDefault(data.paginacao?.pagina_anterior, 1),
      current: data.paginacao?.pagina_atual,
      next: data.paginacao?.proxima_pagina,
      total: data.paginacao?.paginas
    }

    const offerInfo = {
      name: data.oferta.nome,
      mainColor: getValueOrDefault(data.oferta?.cor?.primaria, '#FF6500'),
      secondaryColor: getValueOrDefault(
        data.oferta?.cor?.secundaria,
        '#FF6500'
      ),
      textColor: getValueOrDefault(data.oferta?.cor?.letra, '#FFFFFF'),
      startDate: getValueOrDefault(data.oferta?.data_inicio, 0),
      endDate: getValueOrDefault(data.oferta?.data_fim, 0),
      title: data.oferta?.title || '',
      description: data.oferta?.description || '',
      regulation: data.oferta?.regulamento || ''
    }

    return {
      data: products,
      needLogin: data.logar,
      offerInfo,
      pagination,
      amount: data.quant_ofertas,
      filters: {
        departments: data.categorias.map((category) => ({
          name: category.nome,
          slug: category.amigavel,
          code: category.codigo,
          sections: category.secs.map((sec) => ({
            name: sec.nome,
            slug: sec.amigavel,
            code: sec.codigo
          }))
        })),
        brand: data.filtro?.marcas?.map((item) => ({
          code: String(item.codigo),
          name: item.nome
        })),
        discountRange: {
          min: data.filtro?.desconto_minimo,
          max: data.filtro?.desconto_maximo
        },
        priceRange: {
          min: data.filtro?.valor_minimo,
          max: data.filtro?.valor_maximo
        },
        highlightedFilters: data.filtro.filtro_destaques || [],
        highlightedStampFilters: getHighlightedStampFilters(
          data.filtro.selos_destaques
        ),
        highlightedDiscountPercentages: data.filtro.descontos_destaques || []
      }
    }
  } catch (e) {
    console.log(e)
  }
}

export default offerModel

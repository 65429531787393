import React from 'react'

import { STATIC_KABUM_IMAGES_K5 } from 'settings/kernel'

import * as S from './styles'

const NotFoundPage: React.FC = () => {
  return (
    <>
      <S.TextContainer>
        <S.FirstText>Ops! A página não foi encontrada.</S.FirstText>

        <S.SecondText>
          O nosso ninja não encontrou a página que você digitou.
        </S.SecondText>

        <S.ThirdText>
          Se o erro persistir, por favor, nos informe entrando em contato pela
          <a
            href='/atendimento'
            className='text-xs text-secondary-500 font-semibold underline ml-4'
          >
            Central de atendimento
          </a>
          .
        </S.ThirdText>

        <S.FourthText>
          <strong>Erro 404:</strong> O conteúdo não está mais disponível ou você
          digitou o endereço errado.
        </S.FourthText>
      </S.TextContainer>

      <S.ImageContainer>
        <S.NinjaImage
          src={`${STATIC_KABUM_IMAGES_K5}/ninja_pensativo.png`}
          width={316}
          height={500}
        />
      </S.ImageContainer>
    </>
  )
}

export default NotFoundPage

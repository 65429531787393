import axios from 'axios'
import { axiosException } from 'exception/axios'
import offerModel, { OfferModel } from 'models/ofertas'
import { BACKEND_LISTAGEM_OFERTA_URL } from 'settings/kernel'

import { GetOffersParams, OfferResponse } from './ofertas.types'

export async function getOffers(params: GetOffersParams): Promise<OfferModel> {
  try {
    const { data } = await axios.get<OfferResponse>(
      `${BACKEND_LISTAGEM_OFERTA_URL}`,
      {
        params: {
          campanha: params.campaign,
          pagina: params.page || 1,
          limite: params.limit || 20,
          marcas: params.brands ? params.brands.join(',') : '',
          ordem: params.order || '',
          valor_min: params.minPrice || '',
          valor_max: params.maxPrice || '',
          estrelas: params.evaluation || '',
          desconto_minimo: params.minDiscount || '',
          desconto_maximo: params.maxDiscount || '',
          dep: params.dep || '',
          sec: params.sec || '',
          vendedor_codigo: params.soldByKabum || '',
          string: params.string || '',
          app: 1,
          separar_campos: params.filterByType || 1,
          tipo: params.type || '',
          selo: params.stamps ? params.stamps.join(',') : '',
          entrega_flash: params.flashShipping || 0,
          frete_gratis: params.freeShipping || 0,
          ...(params.isUserPrime
            ? { usuario_prime: Number(params.isUserPrime) }
            : null)
        }
      }
    )

    return offerModel(data)
  } catch (error) {
    throw axiosException(error, `GET OFERTA: ${params.campaign}`)
  }
}

import { Button } from 'components/Button/styles'
import styled from 'styled-components'

import { styledProps } from './OffersListingHeader.types'

export const Container = styled.div<styledProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row-reverse;
  height: ${(props) => props.theme.rem(50)};
  padding: 0 ${(props) => props.theme.spacing.sm} 0
    ${(props) => props.theme.spacing.sm};
  background: ${({ mainColor }) => mainColor};
  gap: 0;

  ${Button} {
    width: ${(props) => props.theme.rem(158)};
    height: ${(props) => props.theme.rem(32)};
    border: solid 1px ${({ textColor }) => textColor};
    color: ${({ textColor }) => textColor};
    background-color: transparent;
    ${(props) => props.theme.font(12, 16, 600)};
    :hover {
      opacity: 0.8;
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.minTablet}) {
    flex-direction: row;
    height: ${(props) => props.theme.rem(56)};
    gap: ${(props) => props.theme.spacing.lg};

    ${Button} {
      max-width: ${(props) => props.theme.rem(170)};
      max-height: ${(props) => props.theme.rem(34)};
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.minDesktop}) {
    height: ${(props) => props.theme.rem(70)};
    padding: 0 ${(props) => props.theme.spacing.md} 0
      ${(props) => props.theme.spacing.md};

    ${Button} {
      max-width: ${(props) => props.theme.rem(190)};
      width: ${(props) => props.theme.rem(190)};
      max-height: ${(props) => props.theme.rem(40)};
      ${(props) => props.theme.font(14, 24, 700)};
    }
  }
`

export const FigureOfferImage = styled.figure`
  width: 100%;
  aspect-ratio: 360 / 196;

  @media (min-width: ${({ theme }) => theme.breakpoints.minTablet}) {
    aspect-ratio: 600 / 118;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.minDesktop}) {
    aspect-ratio: 960 / 188;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.minDesktopLarge}) {
    aspect-ratio: 1377 / 234;
  }
`

export const OfferImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`

export const OfferTitle = styled.div<styledProps>`
  display: none;

  @media (min-width: ${({ theme }) => theme.breakpoints.minTablet}) {
    display: flex;
    align-items: center;
    text-transform: uppercase;
    color: ${(props) => props.textColor};
    ${({ theme }) => theme.font(16, 24, 700)};
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.minDesktop}) {
    ${({ theme }) => theme.font(24, 34, 700)};
  }
`

export const OfferCountdown = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

export const CountdownLabel = styled.div<styledProps>`
  display: none;
  align-items: center;
  color: ${(props) => props.textColor};
  margin-right: ${(props) => props.theme.spacing.xxs};
  ${({ theme }) => theme.font(16, 24, 700)};

  @media (min-width: ${({ theme }) => theme.breakpoints.minTablet}) {
    display: none;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.minDesktop}) {
    display: flex;
    ${({ theme }) => theme.font(20, 30, 700)};
  }
`

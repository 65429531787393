import { IconJustArrived, IconTimerFast } from '@k_frontend/icons'

import { FilterTypes } from './FilteringTypes.types'

export const FILTER_BUTTONS = [
  {
    type: FilterTypes.ACTIVE,
    label: 'Ofertas ativas',
    Icon: IconJustArrived
  },
  {
    type: FilterTypes.SCHEDULED,
    label: 'Próximas ofertas',
    Icon: IconTimerFast
  },
  {
    type: FilterTypes.CLOSED,
    label: 'Encerradas',
    Icon: null
  }
]

import { getValueOrDefault } from '@k_frontend/core'
import { BannerResponse } from 'services/banner/banner'

import { BannerModel } from './banner.types'

export function bannerOfferModel(data: BannerResponse): BannerModel {
  return {
    banner: getValueOrDefault(data.banner.imagem, null),
    bannerMobile: getValueOrDefault(data.banner.img_highres, null),
    id: getValueOrDefault(data?.banner?.banner_id, ''),
    creative: getValueOrDefault(data?.banner?.creative, '')
  }
}

import styled from 'styled-components'

export const Breadcrumb = styled.h1`
  display: flex;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacing.xxs};
  ${({ theme }) => theme.font(12, 16, 400)};
  color: ${({ theme }) => theme.colors.black(800)};

  > b {
    ${({ theme }) => theme.font(12, 16, 700)};
  }

  a {
    ${({ theme }) => theme.font(12, 16)};
    text-decoration: none;
    color: ${({ theme }) => theme.colors.black(800)};
  }

  a:hover {
    text-decoration: underline;
  }

  > span {
    ${({ theme }) => theme.font(14, 18)};
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.minTablet}) {
    margin: ${({ theme }) => theme.spacing.xxs} 0;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.minDesktop}) {
    margin: ${({ theme }) => theme.spacing.lg} 0
      ${({ theme }) => theme.spacing.md} 0;
    ${({ theme }) => theme.font(14, 18, 400)};

    > b {
      ${({ theme }) => theme.font(14, 18, 700)};
    }

    a {
      ${({ theme }) => theme.font(14, 18)};
    }
  }
`

import { KABUM_URL } from 'settings/kernel'

import * as S from './Breadcrumb.styles'
import { BreadcrumbProps } from './Breadcrumb.type'

export default function Breadcrumb({ labels }: BreadcrumbProps): JSX.Element {
  return (
    <S.Breadcrumb id="breadcrumbListagem">
      <b>Você está em:&nbsp;</b>
      {labels.map((item, index) => {
        const { code, name, slug } = item
        const isFirstItem = index === 0

        return (
          <span key={code}>
            {isFirstItem ? ' ' : ' > '}
            <a href={`${KABUM_URL}/${slug}`}>{name} </a>
          </span>
        )
      })}
    </S.Breadcrumb>
  )
}

import axios from 'axios'
import { axiosException } from 'exception/axios'
import { BannerModel, bannerOfferModel } from 'models/banner/banner'
import { BACKEND_BANNER_OFERTA_URL } from 'settings/kernel'

import { BannerResponse } from './banner.types'

export async function getBannerOffer(string: string): Promise<BannerModel> {
  try {
    const { data } = await axios.get<BannerResponse>(
      `${BACKEND_BANNER_OFERTA_URL}/${string}`
    )

    return bannerOfferModel(data)
  } catch (error) {
    throw axiosException(error, `GET BANNER: ${string}`)
  }
}

import { Modal } from '@k_frontend/ui'
import Button from 'components/Button'
import IconModalRules from 'components/Icons/IconModalRules'

import * as S from './OfferModalRules.styles'
import { OfferModalRulesProps } from './OfferModalRules.types'

export default function OfferModalRules({
  isOpen,
  handleClose,
  regulation
}: OfferModalRulesProps) {
  return (
    <Modal.Modal
      isOpen={isOpen}
      handleClose={handleClose}
      id="modalRules"
      customPadding="32px"
    >
      <Modal.ModalHeader handleClose={handleClose}>
        <Modal.ModalHeaderWithText
          icon={<IconModalRules />}
          title="Condições da promoção"
        />
      </Modal.ModalHeader>
      <>
        <S.Content
          dangerouslySetInnerHTML={{
            __html: regulation?.replaceAll('\n', '<br />')
          }}
        />
        <S.ButtonWrapper>
          <Button onClick={handleClose}>Fechar</Button>
        </S.ButtonWrapper>
      </>
    </Modal.Modal>
  )
}

import { UnexpectedError, getValueOrDefault } from '@k_frontend/core'
import { HttpClient } from 'adapters/httpClient'
import { SERVICES_PUB } from 'infra/environmentVariants'
import { formatStampsType } from 'utils/formatStampsType'
import { formatProductFriendlyName } from 'utils/friendlyName'
import type {
  AttributesResponseApi,
  SponsoredProducts,
  SponsoredProductsHeaders,
  SponsoredProductsParams,
  SponsoredProductsResponseApi
} from './sponsoredProducts.types'

export class SponsoredProductsService {
  constructor(private readonly httpClient = new HttpClient()) {}

  async getSponsoredProducts(
    headers: SponsoredProductsHeaders,
    params: SponsoredProductsParams
  ): Promise<SponsoredProducts> {
    const { body, error, statusCode } =
      await this.httpClient.request<SponsoredProductsResponseApi>({
        method: 'get',
        url: `${SERVICES_PUB}/catalog/v2/sponsored_products`,
        headers: {
          'client-id': headers['client-id'],
          session: headers.session
        },
        params: {
          query: params.query,
          context: params.context
        },
        timeout: 2000
      })

    const shouldThrowError = !!error && statusCode !== 404

    if (shouldThrowError) throw new UnexpectedError()

    if (body) return this.formatSponsoredProducts(body)
  }

  private getOldPrice(attributes: AttributesResponseApi) {
    const { offer, price, old_price } = attributes

    return offer ? price : old_price
  }

  private formatSponsoredProducts(
    sponsoredProducts: SponsoredProductsResponseApi
  ): SponsoredProducts {
    return {
      data: sponsoredProducts.data.map((sponsoredProduct) => {
        const { attributes, relationships } = sponsoredProduct
        return {
          code: sponsoredProduct.id,
          name: attributes.title,
          description: attributes.description,
          friendlyName: formatProductFriendlyName(attributes.title),
          sellerName: getValueOrDefault(
            attributes.marketplace?.seller_name,
            'KaBuM!'
          ),
          sellerId: getValueOrDefault(attributes.marketplace?.seller_id, 0),
          offerIdMarketplace: getValueOrDefault(
            attributes.marketplace?.product_id,
            0
          ),
          category: attributes.menu,
          brand: getValueOrDefault(attributes?.manufacturer?.name, ''),
          image: attributes.images?.[0],
          images: attributes.images,
          price: attributes.price,
          primePrice: getValueOrDefault(attributes.prime?.price, 0),
          primePriceWithDiscount: getValueOrDefault(
            attributes.prime?.price_with_discount,
            0
          ),
          discountPercentagePrime: getValueOrDefault(
            attributes.prime?.discount_percentage,
            0
          ),
          oldPrice: this.getOldPrice(attributes),
          oldPrimePrice: this.getOldPrice(attributes),
          maxInstallment: getValueOrDefault(attributes?.max_installment, ''),
          priceWithDiscount: getValueOrDefault(
            attributes.price_with_discount,
            0
          ),
          priceMarketplace: getValueOrDefault(
            attributes?.marketplace?.price,
            0
          ),
          quantity: getValueOrDefault(attributes.offer?.quantity_available, 0),
          rating: getValueOrDefault(attributes.score_of_ratings, 0),
          ratingCount: attributes.number_of_ratings,
          available: attributes.available,
          preOrderDate: attributes.date_pre_order,
          ufsFlash: attributes.ufs_flash,
          stamps: attributes.stamps && {
            id: attributes.stamps.id,
            title: attributes.stamps.title,
            name: attributes.stamps.name,
            backgroundColor: attributes.stamps.background_color,
            fontColor: attributes.stamps.font_color,
            type: formatStampsType(attributes.stamps.type)
          },
          flags: {
            isOpenbox: !!attributes.is_openbox,
            isFreeShipping: !!attributes.has_free_shipping,
            isFreeShippingPrime: !!attributes.has_free_shipping_for_prime_user,
            isPreOrder: !!attributes.is_pre_order,
            isFlash: attributes?.ufs_flash?.length > 0,
            isPrime: !!attributes.is_prime,
            isOffer: !!attributes.offer,
            hasGift: !!relationships?.gift?.data?.id,
            isSponsored: true
          },
          prime: attributes.prime && {
            price: attributes.prime.price,
            priceWithDiscount: attributes.prime.price_with_discount,
            discountPercentage: attributes.prime.discount_percentage,
            save: attributes.prime.save,
            maxInstallmentPrime: attributes.max_installment_prime
          },
          offer: attributes.offer && {
            id: attributes.offer.id,
            name: attributes.offer.name,
            referenceBanner: attributes.offer.reference_banner,
            endsAt: attributes.offer.ends_at,
            startsAt: getValueOrDefault(attributes.offer?.starts_at, 0),
            price: attributes.offer.price,
            priceWithDiscount: attributes.offer.price_with_discount,
            discountPercentage: attributes.offer.discount_percentage,
            quantityAvailable: attributes.offer.quantity_available,
            hashCode: attributes.offer.hash_code
          },
          tagCode: getValueOrDefault(attributes.tag_code, 0),
          thumbnail: attributes.images?.[0],
          newtail: attributes.newtail && {
            clickUrl: attributes.newtail.click_url,
            destinationUrl: attributes.newtail.destination_url,
            impressionUrl: attributes.newtail.impression_url,
            viewUrl: attributes.newtail.view_url
          }
        }
      })
    }
  }
}

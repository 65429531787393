import { formatSponsoredProductsHeadersParamsProps } from './formatSponsoredProductsHeadersParams.types'

export function formatSponsoredProductsHeadersParams({
  clientId,
  session,
  query,
  context
}: formatSponsoredProductsHeadersParamsProps) {
  return {
    headers: {
      'client-id': clientId || '',
      session: session
    },
    params: { query: String(query), context: context }
  }
}

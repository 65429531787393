import styled, { css } from 'styled-components'

import { FilterButtonProps } from './FilteringTypes.types'

export const Container = styled.section`
  display: flex;
  gap: ${({ theme }) => theme.spacing.xxs};
  justify-content: start;
  align-items: center;
  flex: 0;

  @media (min-width: ${({ theme }) => theme.breakpoints.minTablet}) {
    gap: ${({ theme }) => theme.spacing.sm};
  }
  
  @media (min-width: ${({ theme }) => theme.breakpoints.minDesktop}) {
    flex: 1;
  }
`

export const FilterButton = styled.button<FilterButtonProps>`
  ${({ theme }) => theme.font(10, 16, 400, 'capitalize')};
  display: flex;
  flex: 1;
  justify-content: center;
  gap: ${({ theme }) => theme.spacing.xxxs};
  color: ${(props) => props.theme.colors.black(700)};
  background-color: ${({ theme }) => theme.colors.black(400)};
  border-radius: ${({ theme }) => theme.rem(20)};
  padding: ${({ theme }) => `${theme.spacing.xs} ${theme.spacing.xxs}`};
  white-space: nowrap;

  svg * {
    fill: ${(props) => props.theme.colors.black(700)};
  }

  ${({ isSelected }) =>
    isSelected &&
    css`
      font-weight: 700;
      color: ${(props) => props.theme.colors.white(500)};
      background-color: ${({ theme }) => theme.colors.secondary(500)};
      box-shadow: ${({ theme }) => theme.shadow.sm};

      svg * {
        fill: white;
      }
    `}

  @media (min-width: ${({ theme }) => theme.breakpoints.minTablet}) {
    ${({ theme }) => theme.font(12, 16, 400)};
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.minDesktop}) {
    flex-grow: 0;
    flex-shrink: 1;
  }
`

import styled from 'styled-components'

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 384px;
  padding: 0 ${({ theme }) => theme.spacing.sm};
`

export const FirstText = styled.span`
  ${({ theme }) => theme.font(40, 48, 700)};
  color: var(--text-grey);
  margin-bottom: ${({ theme }) => theme.spacing.sm};
`

export const SecondText = styled.span`
  ${({ theme }) => theme.font(16, 28, 400)};
  color: var(--text-grey-mid);
  width: 245px;
  margin-bottom: ${({ theme }) => theme.spacing.lg};
`

export const ThirdText = styled.span`
  ${({ theme }) => theme.font(12, 18, 400)};
  color: var(--text-grey-mid);
`

export const FourthText = styled.span`
  ${({ theme }) => theme.font(12, 18, 400)};
  color: var(--text-grey-mid);

  strong {
    ${({ theme }) => theme.font(12, 18, 700)};
    color: var(--text-grey-mid);
  }
`

export const ImageContainer = styled.div`
  display: flex;
  padding: 0px;
  position: relative;
  align-self: flex-end;
  margin-top: ${({ theme }) => theme.spacing.lg};
  overflow: hidden;
`

export const NinjaImage = styled.img`
`

import theme from 'theme'

export const TIMER_CONFIG = {
  svg: {
    height: '24px',
    marginRight: '8px'
  },
  text: {
    size: '24px',
    width: '167px',
    textColor: theme.colors.white(500)
  },
  textTransform: 'lowercase'
}

export const TIMER_CONFIG_MOBILE = {
  svg: {
    height: '24px',
    marginRight: '8px'
  },
  text: {
    size: '16px',
    width: '116px',
    textColor: theme.colors.white(500)
  },
  textTransform: 'lowercase'
}

const IconCloseModal = (props) => {
  const { width, fill } = props

  const size = width ? width : 24
  const color = fill ? fill : '#FF6500'

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="https://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22 3.76101L20.239 2L12 10.2075L3.76101 2L2 3.76101L10.2075 12L2 20.239L3.76101 22L12 13.7925L20.239 22L22 20.239L13.7925 12L22 3.76101Z"
        fill={color}
      />
    </svg>
  )
}

export { IconCloseModal as default } 
import styled from 'styled-components'

export const Content = styled.p`
  ${(props) => props.theme.font(14, 24, 400)};
  margin: ${(props) => props.theme.spacing.sm};
  padding: ${(props) => props.theme.spacing.sm} 0
    ${(props) => props.theme.spacing.lg};
  color: ${(props) => props.theme.colors.black(700)};
`

export const ButtonWrapper = styled.div`
  width: ${(props) => props.theme.rem(166)};
  margin: ${(props) => props.theme.spacing.lg} auto 0;
`

import styled, { keyframes } from 'styled-components'

const DescriptioAnimation = keyframes`
  from {
    transform: translateX(0)
  } to {
    transform: translateX(16px)
  }
`

export const Wrapper = styled.div`
  display: flex;
  z-index: 10;
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  align-items: center;
  justify-content: space-between;
  background: ${({ theme }) => theme.colors.secondary(500)};
  padding: ${({ theme }) => theme.spacings('xxs', 'lg')};
  gap: ${({ theme }) => theme.spacing.sm};
  height: fit-content;

  @media (min-width: ${({ theme }) => theme.breakpoints.minTablet}) {
    gap: ${({ theme }) => theme.spacing.xs};
  }
`

export const ImageWrapper = styled.div`
  position: absolute;
  bottom: 0;
  width: ${({ theme }) => theme.rem(79)};
  height: ${({ theme }) => theme.rem(94)};

  > img {
    width: 100%;
  }
`

export const Description = styled.p`
  animation: ${DescriptioAnimation} 1s ease-out alternate infinite;
  @media (prefers-reduced-motion) {
    animation: unset;
  }
  ${({ theme }) => theme.font(12, 18, 600)};
  color: ${({ theme }) => theme.colors.white(500)};
  margin-left: ${({ theme }) => theme.rem(79)};

  @media (min-width: ${({ theme }) => theme.breakpoints.minDesktop}) {
    ${({ theme }) => theme.font(14, 18, 600)};
  }
`

export const CloseButton = styled.button`
  background: transparent;
  display: flex;
  gap: ${({ theme }) => theme.spacing.xxs};
  padding: ${({ theme }) => theme.spacing.xxs};
  align-items: center;

  svg {
    width: ${({ theme }) => theme.rem(16)};
    height: ${({ theme }) => theme.rem(16)};
  }
`

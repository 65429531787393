import { makeLoadFeatureFlagUseCase } from 'factories/useCases/makeLoadFeatureFlagUseCase'

async function setSponsoredProductsVariation(session: string) {
  try {
    const sponsoredProductsVariation = await (
      await makeLoadFeatureFlagUseCase()
    ).load('campaign_sponsored_product', session)

    localStorage.setItem(
      'sponsoredProductsVariation',
      JSON.stringify(sponsoredProductsVariation)
    )

    return sponsoredProductsVariation
  } catch {
    return false
  }
}

export async function getSponsoredProductsVariation(session: string) {
  const isSponsoredProductsEnabled = getSponsoredProductsVariationCache()
  if (!isSponsoredProductsEnabled) {
    const sponsoredProductsVariation =
      await setSponsoredProductsVariation(session)
    return sponsoredProductsVariation
  }
  return isSponsoredProductsEnabled === 'true'
}

function getSponsoredProductsVariationCache() {
  return localStorage.getItem('sponsoredProductsVariation')
}

import { Col } from '@k_frontend/ui'
import styled from 'styled-components'
import { tv } from 'tailwind-variants'

import { ContainerTagsStyledProps } from './ofertas.types'

export const Body = styled.body`
  background-color: ${({ theme }) => theme.colors.black(300)};
  overflow-x: hidden;
`

export const ContentHeader = styled.div<ContainerTagsStyledProps>`
  display: flex;
  flex-direction: column;
  flex-flow: column-reverse;
  width: 100%;
  margin-bottom: ${({ theme }) => theme.spacing.lg};
  gap: ${({ theme }) => theme.spacing.sm};
  height: auto;
  min-height: ${({ theme, showFilterTypes }) => (showFilterTypes ? theme.rem(184) : theme.rem(128))};

  @media (min-width: ${({ theme }) => theme.breakpoints.minTablet}) {
    gap: ${({ theme }) => theme.spacing.md};
    min-height: ${({ theme, showFilterTypes }) => (showFilterTypes ? theme.rem(98) : theme.rem(34))};
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.minDesktop}) {
    flex-direction: row;
    margin-bottom: ${({ theme, showTags }) => (showTags ? 0 : theme.spacing.lg)};
    gap: ${({ theme }) => theme.spacing.xxs};
    justify-content: flex-end;
    flex-wrap: wrap;
  }
`

export const AsideBanner = styled.a`
  margin-top: ${({ theme }) => theme.spacing.lg};
  img {
    width: ${({ theme }) => theme.rem(280)};
    height: ${({ theme }) => theme.rem(525)};
  }
`

export const ColCustomListing = styled(Col)`
  flex-direction: column;
`

export const skeleton = tv({
  base: 'flex w-full animate-pulse rounded-2 bg-black-500',
  variants: {
    role: {
      banner: 'w-full h-full desktopLarge:h-[80%]',
      asideFilter: 'h-[500px] hidden desktop:flex',
      orderingProducts: 'w-full h-[128px] desktop:w-[450px] tablet:h-[34px]'
    }
  }
})

export const NotFoundContainer = styled.div`
  width: 100%;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`

import Button from './view'

export default function ButtonController(props) {
  const { onClick, loading, disabled, children, outlined, ...rest } = props

  return (
    <Button
      onClick={onClick}
      loading={loading}
      disabled={disabled}
      outlined={outlined}
      {...rest}
    >
      {children}
    </Button>
  )
}

const IconModalRules = () => (
  <svg
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="https://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 0.5C1.60218 0.5 1.22064 0.658035 0.93934 0.93934C0.658035 1.22064 0.5 1.60218 0.5 2V8.879C0.500085 9.27679 0.658176 9.65826 0.9395 9.9395L11.4395 20.4395C11.7208 20.7207 12.1023 20.8787 12.5 20.8787C12.8977 20.8787 13.2792 20.7207 13.5605 20.4395L20.4395 13.5605C20.7207 13.2792 20.8787 12.8977 20.8787 12.5C20.8787 12.1023 20.7207 11.7208 20.4395 11.4395L9.9395 0.9395C9.65826 0.658176 9.27679 0.500085 8.879 0.5H2ZM8 5.75C8 6.34674 7.76295 6.91903 7.34099 7.34099C6.91903 7.76295 6.34674 8 5.75 8C5.15326 8 4.58097 7.76295 4.15901 7.34099C3.73705 6.91903 3.5 6.34674 3.5 5.75C3.5 5.15326 3.73705 4.58097 4.15901 4.15901C4.58097 3.73705 5.15326 3.5 5.75 3.5C6.34674 3.5 6.91903 3.73705 7.34099 4.15901C7.76295 4.58097 8 5.15326 8 5.75Z"
      fill="#FF6500"
    />
  </svg>
)

export { IconModalRules as default } 
import { useState } from 'react'

import IconCloseModal from 'components/Icons/IconCloseModal'
import { STATIC_KABUM } from 'settings/kernel'

import * as S from './FooterLoginNotification.styles'
import { FooterDynamicNotificationProps as Props } from './FooterLoginNotification.types'

export default function FooterLoginNotification(props: Props) {
  const { message } = props
  const [show, setShow] = useState(true)

  if (!show) return null

  return (
    <S.Wrapper>
      <S.ImageWrapper>
        <img src={`${STATIC_KABUM}/conteudo/stamps/ninja.png`} alt="" />
      </S.ImageWrapper>
      <S.Description>{message}</S.Description>
      <S.CloseButton onClick={() => setShow(false)}>
        <IconCloseModal width={20} fill="#fff" />
      </S.CloseButton>
    </S.Wrapper>
  )
}

import { JSX } from 'react'

import { useOffer } from 'hooks/useOffer'

import { FILTER_BUTTONS } from './FilteringTypes.constants'
import * as S from './FilteringTypes.styles'
import { FilterTypes } from './FilteringTypes.types'

function isSelected(currentType: string, targetType: string): boolean {
  if (targetType === FilterTypes.ACTIVE) {
    return currentType === targetType || !currentType
  }
  return currentType === targetType
}

export default function FilteringTypes(): JSX.Element {
  const { params, setParams } = useOffer()

  function handleButtonClick(type: string) {
    setParams({ ...params, type })
  }

  return (
    <S.Container id='blocoTiposListagem'>
      {FILTER_BUTTONS.map(({ type, label, Icon }) => (
        <S.FilterButton
          key={type}
          isSelected={isSelected(params.type, type)}
          onClick={() => handleButtonClick(type)}
        >
          {Icon && <Icon width={16} height={16} />}
          {label}
        </S.FilterButton>
      ))}
    </S.Container>
  )
}

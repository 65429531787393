import type { Data } from 'types/sponsoredProducts'

export function formatSponsoredProductsToCatalog(
  sponsoredProductsData: Data[]
) {
  return (
    sponsoredProductsData?.map(({ brand, flags, offer, ...rest }) => ({
      ...rest,
      productSpecie: 0,
      tagDescription: '',
      weight: 0,
      externalUrl: '',
      manufacturer: {
        id: 0,
        name: brand,
        img: ''
      },
      iframeUrl: '',
      discountPercentage: 0,
      offerDiscount: 0,
      warranty: '',
      dateProductArrived: null,
      html: null,
      photos: null,
      origin: {
        id: null,
        name: null
      },
      colors: {
        main: '',
        text: ''
      },
      paymentMethodsDefault: null,
      paymentMethodsPrime: null,
      promotionBanner: null,
      campaignBanners: null,
      crossCart: [],
      crossSelling: false,
      flags: {
        ...flags,
        isMarketplace: false,
        isPixShipping: false,
        isHighlight: false
      },
      offer: {
        ...offer,
        isPrimeExclusive: false
      }
    })) || []
  )
}

import styled from 'styled-components'

export const Grid = styled.section`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  gap: ${({ theme }) => theme.spacing.sm};
  margin: 0 0 ${({ theme }) => theme.spacing.lg};

  @media (min-width: ${({ theme }) => theme.breakpoints.minTablet}) {
    grid-template-columns: repeat(3, minmax(200px, 1fr));
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.minDesktopLarge}) {
    grid-template-columns: repeat(5, minmax(200px,1fr));
  }
`

export const Listing = styled.main`
  width: 100%;
  display: flex;
  flex-direction: column;
`

export const Pagination = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: ${({ theme }) => theme.spacing.lg} 0;

  .pagination {
    display: flex;

    .page,
    .nextLink,
    .prevLink,
    .break {
      margin: 0 ${({ theme }) => theme.spacing.xxs};
      color: ${(props) => props.theme.colors.black(550)};
      ${({ theme }) => theme.font(16, 28, 700)};
    }

    .active {
      color: ${(props) => props.theme.colors.secondary(500)};
    }
  }
`

export const Page = styled.button`
  background: transparent;
  ${({ theme }) => theme.font(16, 28, 700)};
  color: ${({ theme }) => theme.colors.black(550)};
`

export const PaginationContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`
